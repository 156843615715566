import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';

import Admin from 'react-amanda/admin';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Admin config={{
    mainNavs: [{
      title: 'About',
      url: 'Index',
      icon: React.lazy(() => import('@mui/icons-material/Stars'))
    }, {
      title: 'Installation',
      url: 'Installation',
      icon: React.lazy(() => import('@mui/icons-material/Anchor'))
    }, {
      title: 'Usage',
      url: 'Usage',
      icon: React.lazy(() => import('@mui/icons-material/AutoGraph'))
    }, {
      title: 'Configure',
      icon: React.lazy(() => import('@mui/icons-material/Coronavirus')),
      childs: [{
        title: 'Verify Login Status',
        url: 'CheckAuth',
        icon: React.lazy(() => import('@mui/icons-material/Flaky'))
      }, {
        title: 'Authentication',
        url: 'Authentication',
        icon: React.lazy(() => import('@mui/icons-material/Login'))
      }, {
        title: 'Logout',
        url: 'Logout',
        icon: React.lazy(() => import('@mui/icons-material/Logout'))
      }, {
        title: 'Logout Text',
        url: 'LogoutText',
        icon: React.lazy(() => import('@mui/icons-material/Abc'))
      }, {
        title: 'Main Navigation',
        url: 'MainNav',
        icon: React.lazy(() => import('@mui/icons-material/Menu'))
      }, {
        title: 'Childs',
        url: 'MainNavChilds',
        icon: React.lazy(() => import('@mui/icons-material/List'))
      }, {
        title: 'Multi-level Directory',
        url: 'MultiLelDir',
        icon: React.lazy(() => import('@mui/icons-material/Lan'))
      }, {
        title: 'Title and Logo',
        url: 'TitleAndLogo',
        icon: React.lazy(() => import('@mui/icons-material/Title'))
      }, {
        title: 'Header Menus',
        url: 'HeaderMenus',
        icon: React.lazy(() => import('@mui/icons-material/AlignVerticalTop'))
      }, {
        title: 'More Menus',
        url: 'MoreMenus',
        icon: React.lazy(() => import('@mui/icons-material/ReadMore'))
      }, {
        title: 'Hide Header Bar',
        url: 'HideHeader',
        icon: React.lazy(() => import('@mui/icons-material/BorderTop'))
      }, {
        title: 'Customize Login Page',
        url: 'CustomLoginPage',
        icon: React.lazy(() => import('@mui/icons-material/CoPresent'))
      }]
    }, {
      title: 'ELink',
      url: 'ELinkDoc',
      icon: React.lazy(() => import('@mui/icons-material/AddLink'))
    }, {
      title: 'ENavigate',
      url: 'ENavigate',
      icon: React.lazy(() => import('@mui/icons-material/SwitchAccessShortcutAdd'))
    }, {
      title: 'APIs',
      icon: React.lazy(() => import('@mui/icons-material/Api')),
      childs: [{
        title: '$setOptions',
        url: 'SetOptions',
        icon: React.lazy(() => import('@mui/icons-material/AlignHorizontalCenter'))
      }, {
        title: '$close',
        url: 'Close',
        icon: React.lazy(() => import('@mui/icons-material/CommentsDisabled'))
      }, {
        title: 'params',
        url: 'Params',
        icon: React.lazy(() => import('@mui/icons-material/DataArray'))
      }, {
        title: 'Set Login Status',
        url: 'SetLoginStatus',
        icon: React.lazy(() => import('@mui/icons-material/AltRoute'))
      }, {
        title: 'Dialog',
        url: 'DialogDoc',
        icon: React.lazy(() => import('@mui/icons-material/DynamicFeed'))
      }]
    }, {
      title: 'Contact Me',
      url: 'Contact',
      icon: React.lazy(() => import('@mui/icons-material/AlternateEmail'))
    }],
    // header: {
    //   items: [{
    //     title: 'Settings',
    //     url: 'Settings',
    //     icon: React.lazy(() => import('@mui/icons-material/SettingsSuggest'))
    //   }],
    // },
    eleHeader: React.lazy(() => import('./myheader')),
    moreMenus: [{
      title: 'Settings',
      url: 'Settings',
      icon: React.lazy(() => import('@mui/icons-material/SettingsSuggest'))
    }],
    checkAuth: (callbackFun) => {
      setTimeout(() => {
        // callbackFun(false);
        callbackFun(localStorage.getItem('authed'));
      }, 1500);
    },
    login: {
      fn: (uname, pwd, callbackFun) => {
        setTimeout(() => {
          if(uname === pwd) {
            localStorage.setItem('authed', 'authed');
            callbackFun(true);
          } else {
            callbackFun(false);
          }
        }, 500);
      },
      defaultUserName: 'test',
      defaultPassword: 'test',
      // page: React.lazy(() => import('./mylogin'))
    },
    logout: (callbackFun) => {
      localStorage.removeItem('authed');
      callbackFun(true);
    },
    // title: '后台管理系统',
    // logo: 'http://www.var6.cn/imgs/index/step1.png',
    // eleTitle: React.lazy(() => import('./mytit')),
    // color: 'secondary',
    // theme: 'dark'
  }}/>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
